import React from 'react'
import { ModalHeader, ButtonWrapper, StyledText } from './index.styles'
import Icon from '@caterdesk/ui--icon'
import { useTheme } from 'styled-components'
import { Button, Dialog, DialogContent, Stack } from '@mui/material'
import { useRouter } from '@/components/Link'

type Props = {
  closeModal: () => void
  basketId: string
}

const UnauthenticatedModal: React.FC<Props> = ({ closeModal, basketId }) => {
  const theme = useTheme()
  const router = useRouter()
  const continueAndCloseModal = (url: string) => {
    const redirectAfterAuth = `/office-catering/checkout/?id=${basketId}`
    localStorage.setItem('redirectAfterAuth', JSON.stringify({ value: redirectAfterAuth }))
    closeModal()
    const href = `${url}?destination=${encodeURIComponent(redirectAfterAuth)}&intent=gm`
    router.push(href)
  }
  return (
    <>
      <Dialog open onClose={() => closeModal()}>
        <DialogContent>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <ModalHeader>
              <Icon
                icon="person"
                size={28}
                fill={theme.colors.primary_lightest}
                variation="circle"
              />
              Sign up to continue
            </ModalHeader>
            <StyledText>
              Signing up takes a few minutes and we’ll keep your basket safe while you do it.
            </StyledText>
            <ButtonWrapper>
              <Button
                variant="contained"
                fullWidth
                onClick={() => continueAndCloseModal('/account/signup')}
              >
                Sign up
              </Button>
              <Button fullWidth onClick={() => continueAndCloseModal('/authentication/login')}>
                Sign in
              </Button>
            </ButtonWrapper>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UnauthenticatedModal
