import { useGetStandingOrderLazyQuery } from '@/generated/graphql'
import { useCallback, useMemo } from 'react'

export const useGetStandingOrderQuery = () => {
  const [run, result] = useGetStandingOrderLazyQuery()

  const error =
    result.error ||
    (result.called && !result.loading && !result.data?.standingOrder ? 'Unknown error' : null)

  const getStandingOrder = useCallback(
    (standingOrderId: string) => {
      void run({ variables: { standingOrderId } })
    },
    [run],
  )

  return useMemo(() => {
    return {
      run: getStandingOrder,
      loading: result.loading,
      standingOrder: result.data?.standingOrder,
      error,
    }
  }, [error, getStandingOrder, result.data?.standingOrder, result.loading])
}
