import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  Stack,
  Collapse,
  Menu,
  ToggleButton as ToggleButtonMui,
  Typography,
  Box,
} from '@mui/material'
import { main } from '@caterdesk/ui--theme'
import { Allergen, Dietary } from '@/generated/graphql'
import { MenuItemFilter, MenuItemFilterSection } from '../../domain/menu-item-filter'
import { Add, Remove } from '@mui/icons-material'
import useGmFiltersStore from '../../helpers/gmFiltersStore'
import Icon, { IconType } from '@caterdesk/ui--icon'

const ToggleButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background: ${main.colors.canvas};
  border-radius: 5px;
  padding: 16px;
  margin-top: 8px;
  width: 100%;
`

type Props = {
  type: MenuItemFilterSection
  label: string
  filters: MenuItemFilter[]
  selectedFilters: MenuItemFilter[]
  selectedDietaries?: MenuItemFilter[]
  hasFiltersSelected: boolean
  asRow: boolean
  visibility: boolean
}

const ToggleButton: React.FC<{
  label: string
  description?: string
  value: string
  qty: number
  backgroundColor: string
  icon?: IconType
  toggleOn: boolean
  onClick?: () => void
}> = ({ label, description, backgroundColor, icon, qty, toggleOn, onClick }) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      onClick={onClick}
      sx={{
        bgcolor: backgroundColor,
        p: 2,
        borderRadius: 2,
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        opacity: toggleOn ? 1 : 0.5,
      }}
    >
      <Box
        sx={{
          borderRadius: '100%',
          height: '38px',
          width: '38px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #fff',
          color: '#fff',
        }}
      >
        {qty}
      </Box>
      <Stack direction="column">
        <Typography color="#fff" fontSize="16px">
          {label}
        </Typography>
        {description && (
          <Typography
            color="#fff"
            fontSize="14px"
            sx={{
              opacity: 0.5,
            }}
          >
            {description}
          </Typography>
        )}

        {icon && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
            }}
          >
            <Icon icon={icon} fill="#00000030" size={90} />
          </Box>
        )}
      </Stack>
    </Stack>
  )
}

const FilterSection = ({
  type,
  label,
  filters,
  selectedFilters,
  selectedDietaries,
  hasFiltersSelected,
  asRow,
  visibility,
}: Props) => {
  const { setDietaryPreferences } = useGmFiltersStore()
  const [expanded, setExpanded] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const emptyFilter = filters.every((filter) => filter.count === 0)
  if (!visibility || emptyFilter) return

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = (value: Dietary | Allergen) => {
    const filter = filters.find((f) => f.value === value)
    const selected = selectedFilters.find((f) => f.value === value)
    const newFilters =
      !selected && filter
        ? [...selectedFilters, filter]
        : selectedFilters.filter((f) => f.value !== value)
    setDietaryPreferences(type, newFilters)
  }

  const handleClick = () => {
    setDietaryPreferences(type, [])
    const toggles = document.getElementsByName(type)
    toggles.forEach((toggle) => ((toggle as HTMLInputElement).checked = false))
  }

  const filterSectionTitle = `${label} ${
    selectedFilters.length > 0 ? `(${selectedFilters.length})` : ''
  }`

  const toggleOn = (value: Dietary | Allergen) => {
    const nuts = Boolean(
      value === Allergen.Peanuts &&
        selectedDietaries?.map((d) => d.value).includes(Dietary.ContainsNuts),
    )
    return selectedFilters.map((f) => f.value).includes(value) || nuts
  }

  return (
    <Stack spacing={1} width={{ xs: '100%', md: asRow ? '250px' : '100%' }}>
      <ToggleButtonMui
        value=""
        selected={selectedFilters.length > 0}
        size={asRow ? 'large' : 'medium'}
        color="primary"
        sx={{ width: '100%', justifyContent: 'space-between', backgroundColor: 'background.paper' }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          setExpanded((prev) => !prev)
        }}
      >
        {filterSectionTitle}
        {!expanded || !open ? <Add /> : <Remove />}
      </ToggleButtonMui>
      {asRow ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Stack paddingBottom={1}>
            <Button
              onClick={() => handleClick()}
              disabled={selectedFilters.length === 0}
              sx={{ marginTop: -1, marginLeft: 'auto' }}
            >
              Clear
            </Button>
            <ToggleButtons>
              {filters
                .filter((filter) => filter.count >= 1)
                .map((filter) => (
                  <div key={filter.value}>
                    <ToggleButton
                      label={filter.label}
                      description={filter.description}
                      value={filter.value}
                      qty={filter.count}
                      backgroundColor={filter.color}
                      icon={filter.icon}
                      toggleOn={!hasFiltersSelected || toggleOn(filter.value)}
                      onClick={() => handleChange(filter.value)}
                    />
                  </div>
                ))}
            </ToggleButtons>
          </Stack>
        </Menu>
      ) : (
        <Collapse in={expanded} timeout="auto">
          <Stack paddingBottom={1}>
            <Button
              onClick={() => handleClick()}
              disabled={selectedFilters.length === 0}
              sx={{ marginTop: -1, marginLeft: 'auto' }}
            >
              Clear
            </Button>
            <ToggleButtons>
              {filters
                .filter((filter) => filter.count >= 1)
                .map((filter) => (
                  <div key={filter.value}>
                    <ToggleButton
                      label={filter.label}
                      description={filter.description}
                      value={filter.value}
                      qty={filter.count}
                      backgroundColor={filter.color}
                      icon={filter.icon}
                      toggleOn={!hasFiltersSelected || toggleOn(filter.value)}
                    />
                  </div>
                ))}
            </ToggleButtons>
          </Stack>
        </Collapse>
      )}
    </Stack>
  )
}

export default FilterSection
