import {
  BasketFragment,
  BasketItemFragment,
  useUpdateItemQtyInGmBasketMutation,
} from '@/generated/graphql'

export type UpdateItemQty = (
  basket: BasketFragment,
  item: Pick<BasketItemFragment, 'itemReference'>,
  qty: number,
) => void

export const useUpdateItemQtyMutation = (
  setDeliveryFee?: number,
  isStandingOrderTemplate?: boolean,
) => {
  const [run, result] = useUpdateItemQtyInGmBasketMutation()

  if (!setDeliveryFee && setDeliveryFee !== 0) throw new Error('No delivery fee set')

  const error =
    result.error ||
    (result.called && !result.loading && !result.data?.basket ? 'Unknown error' : null)

  const updateItemQty: UpdateItemQty = (basket, { itemReference }, qty) => {
    const optimisticItems = basket.items.map((basketItem) =>
      basketItem.itemReference === itemReference
        ? {
            ...basketItem,
            qty,
          }
        : basketItem,
    )

    void run({
      variables: {
        basketId: basket.id,
        isStandingOrderTemplate: Boolean(isStandingOrderTemplate),
        itemReference,
        qty,
      },
      optimisticResponse: {
        basket: {
          ...basket,
          items: optimisticItems,
        },
      },
    })
  }

  return {
    run: updateItemQty,
    loading: result.loading,
    error,
  }
}
