import React from 'react'
import { IconButton, Stack, Typography, Box } from '@mui/material'
import { Close } from '@mui/icons-material'

export type Props = {
  basketFriendlyId?: string
  standingOrderName?: string
  onCloseBasket: () => void
}

export const BasketBanner: React.FC<Props> = ({
  basketFriendlyId,
  standingOrderName,
  onCloseBasket,
}) => {
  return (
    <Box padding={{ x: 0, y: 4 }} sx={{ backgroundColor: 'secondary.main' }}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <IconButton style={{ position: 'relative', zIndex: 2 }} onClick={() => onCloseBasket()}>
          <Close color="primary" />
        </IconButton>
        <Typography
          right="20px"
          position="relative"
          flexGrow={1}
          textAlign="center"
          color="secondary.contrastText"
        >
          {standingOrderName
            ? `Standing order - ${standingOrderName}`
            : `Draft ID - ${basketFriendlyId}`}
        </Typography>
      </Stack>
    </Box>
  )
}
