import styled from 'styled-components'
import { mobileMediaQuery } from '@caterdesk/ui--theme'
import { TextExtraSmall, TextSmall } from '@caterdesk/ui--typography'

export const ItemCard = styled.button`
  text-align: initial;
  border: none;
  outline: none;
  display: flex;
  padding: 16px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  background-color: ${(p) => p.theme.colors.canvas};
`

export const Title = styled.h2`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  font-size: ${(p) => p.theme.fontSizes.s};
  color: ${(p) => p.theme.colors.text_heading};
  line-height: 20px;
  margin-top: 0;
`

export const Description = styled.p`
  font-size: ${(p) => p.theme.fontSizes.xs};
  color: ${(p) => p.theme.colors.text};
  line-height: 27px;
  margin-bottom: 0;

  @media ${mobileMediaQuery} {
    font-size: ${(p) => p.theme.fontSizes.xxs};
    line-height: 20px;
  }
`

export const Divider = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.background};
  height: 1px;
  width: 100%;
  margin: 8px 0;
  flex-basis: 100%;
`

export const Price = styled.div`
  color: ${(p) => p.theme.colors.text_heading};
  font-size: ${(p) => p.theme.fontSizes.xs};
  font-weight: ${(p) => p.theme.fontWeights.semi};
  text-align: center;
`

export const DietaryCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-size: 10px;
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  font-size: ${(p) => p.theme.fontSizes.xxs};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  background-color: ${(p) => p.theme.colors.background_lightest};
  color: ${(p) => p.theme.colors.text};
`

export const StickyModalSection = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
`

export const SectionTitle = styled(TextSmall)<{ color?: string }>`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  line-height: 25px;
  ${(p) => p.color && `color: ${p.color};`}
`

export const OptionGuideText = styled(TextExtraSmall)`
  color: ${(p) => p.theme.colors.error};
`
