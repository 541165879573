import styled from 'styled-components'
import { Text } from '@caterdesk/ui--typography'
import { mobileMediaQuery } from '@caterdesk/ui--theme'

export const ModalWrapper = styled.div`
  overflow: visible;
  & div {
    overflow: visible;
  }
  & > div > div {
    min-height: 305px;
    height: auto;
    max-height: 60%;
    & > div > div > div {
      padding-bottom: 0;
      h2 {
        padding-right: 0;
      }
    }
  }
  @media ${mobileMediaQuery} {
    & > div > div {
      height: 100%;
      max-height: none;
      & > div > div {
        height: 50%;
        margin-top: 17%;
      }
    }
    input {
      width: 100%;
    }
  }
`

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: ${(p) => p.theme.fontSizes.xl};
  & > span {
    width: 59px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    svg {
      fill: ${(p) => p.theme.colors.primary};
    }
    @media ${mobileMediaQuery} {
      width: 80px;
      height: 80px;
      svg {
        height: 38px;
        width: 38px;
      }
    }
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  @media ${mobileMediaQuery} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid ${(p) => p.theme.colors.background_dark};
    padding: 10px;
  }
`

export const StyledText = styled(Text)`
  margin: 0 16px 16px;
`
