import React from 'react'
import { InputAdornment, TextField } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'

type Props = {
  maxHeadCount?: number
  error?: string
  headCount?: number
  unusable?: boolean
  disabled?: boolean
  onChange: (newHeadcount: number) => void
}

const HeadCount: React.FC<Props> = ({
  maxHeadCount = Infinity,
  error,
  headCount,
  disabled,
  onChange,
}) => {
  const minHeadCount = 0

  const handleChange = ({ value }: { value: string }) => {
    try {
      const number = Number(value)

      if (number < minHeadCount) {
        onChange(minHeadCount)
        return
      }

      if (number > maxHeadCount) {
        onChange(maxHeadCount)
        return
      }
      onChange(Number(value))
    } catch (e) {
      onChange(0)
    }
  }

  const showError = Boolean(error)

  return (
    <>
      <TextField
        size="small"
        fullWidth
        type="number"
        disabled={disabled}
        placeholder="0"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon color="primary" />
            </InputAdornment>
          ),
        }}
        error={showError}
        helperText={error}
        value={headCount === 0 ? '' : headCount}
        onChange={(e) => handleChange(e.target)}
      />
    </>
  )
}

export default HeadCount
