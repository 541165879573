import React, { useState } from 'react'
import styled from 'styled-components'
import Filters from './filters'
import Box from '@caterdesk/ui--box'
import { Text, Title } from '@caterdesk/ui--typography'
import { main } from '@caterdesk/ui--theme'
import { MenuFilterCategory, SelectedMenuFilterCategory } from '../../domain/menu-category-filter'
import { MenuItemFilterSections } from '../../domain/menu-item-filter'
import useAllergenInfoText from '../../helpers/useAllergenInfoText'
import useGmFiltersStore from '../../helpers/gmFiltersStore'
import {
  Autocomplete,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

const FilterInputs = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0;
  gap: 8px;
  border-bottom: 1px solid ${main.colors.background_dark};

  > div {
    flex-basis: 50%;
  }
`

type Props = {
  categories: MenuFilterCategory[]
  filterSections: MenuItemFilterSections
  selectedCategory: SelectedMenuFilterCategory
  onCategoryChange: (category: string) => void
}

const MobileFilters = ({
  categories,
  filterSections,
  selectedCategory,
  onCategoryChange,
}: Props) => {
  const { goals, dietaries, allergens } = useGmFiltersStore()
  const allergenInfoText = useAllergenInfoText()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const categoryOptions = categories.map((category) => ({
    label: category.name,
    value: category.name,
  }))

  const selectedFiltersCount = goals.length + dietaries.length + allergens.length

  const handleCategoryChange = (selectedOption?: string) =>
    selectedOption && onCategoryChange(selectedOption)
  const filterCount = selectedFiltersCount > 0 ? `(${selectedFiltersCount})` : ''

  return (
    <>
      <FilterInputs>
        <Autocomplete
          options={categoryOptions}
          value={{ label: selectedCategory.name, value: selectedCategory.name }}
          onChange={(_, newValue) => handleCategoryChange(newValue?.value)}
          renderInput={(params) => <TextField {...params} />}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          disableClearable
          fullWidth
          sx={{
            bgcolor: '#fff',
          }}
        />
        <TextField
          name="filters"
          value={`Filters ${filterCount}`}
          onClick={() => setModalIsOpen(true)}
          fullWidth
          sx={{
            bgcolor: '#fff',
          }}
          InputProps={{
            readOnly: true,
          }}
        />
      </FilterInputs>

      {modalIsOpen && (
        <Dialog
          open={modalIsOpen}
          fullScreen
          onClose={() => setModalIsOpen(false)}
          sx={{
            position: 'absolute',
          }}
        >
          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
            py={1}
            px={3}
            alignItems="center"
          >
            <DialogTitle sx={{ p: 0 }}>Filter by</DialogTitle>
            <IconButton
              color="inherit"
              onClick={() => setModalIsOpen(false)}
              aria-label="close"
              edge="end"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <DialogContent
            sx={{
              backgroundColor: 'rgb(242, 242, 242)',
            }}
          >
            <Filters asRow={false} filterSections={filterSections} />
            <Box padding={{ x: 0, y: 24 }}>
              <Title size="base">Allergen Notes</Title>
              <Text size="xxs">{allergenInfoText}</Text>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default MobileFilters
