/**
 * Converts a string to a plural
 * @param {string} str
 * @param {number} qty
 * @returns {string}
 */
export const pluralize = (str = '', qty = 0) => {
  if (qty > 1 && str != null) {
    const letters = str.trim().toLowerCase().split('')
    const lastLetter = letters[letters.length - 1]
    const secondLastLetter = letters[letters.length - 2]
    const hasConsonant = !['a', 'e', 'i', 'o', 'u'].includes(secondLastLetter)
    return lastLetter === 'y' && hasConsonant ? `${str.slice(0, -1)}ies` : `${str}s`
  }
  return str
}
/**
 * Truncates a string to the specified length and appends an ellipse
 * @param {string} str
 * @param {number} length
 * @returns {string}
 */
export const truncate = (str: any, length = Infinity) => {
  const safeStr = str || ''
  const strTrimmed = safeStr.trim()
  if (length && str && str.length > length) {
    return `${strTrimmed.slice(0, length).trim()}...`
  }
  return strTrimmed
}
/**
 * Capitalise the first letter of a string
 * @param {string} input The string
 * @return {string}
 */
export const capitalise = (input: any) => `${input.charAt(0).toUpperCase()}${input.slice(1)}`
export const capitalCase = (input: any) =>
  input
    ? input
        .toLowerCase()
        .split(' ')
        .map((word: any) => capitalise(word))
        .join(' ')
    : null
/**
 * Adds string to CSV. Used in location query.
 * @param {string} csv
 * @param {string} string
 * @returns {string}
 */
export const addStringToCSV = (csv: any, string: any) => {
  const selected = csv ? csv.split(',') : []
  const newFilters = new Set([...selected, string])
  // @ts-expect-error unknown
  return [...newFilters].join(',')
}
/**
 * Removes string from CSV. Used in location query.
 * @param {string} csv
 * @param {string} string
 * @returns {string}
 */
export const removeStringFromCSV = (csv: any, string: any) => {
  const selected = csv ? csv.split(',') : []
  const rtn = selected.filter((r: any) => r !== `${string}`).join(',')
  return rtn
}
export const returnIndefiniteArticle = (str: any) => {
  const isVowel = /[aeiou]/i.test(str[0])
  return isVowel ? 'an' : 'a'
}
