import React from 'react'
import moment from 'moment'
import { getMinDateAllowed } from '@caterdesk/utils--gm-validation'
import { getDay, dateIsInDates } from '@/components/page-specific/gm/helpers/date'
import { CloseButton } from './styles'
import { IconButton } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'
import { MenuVendorLocationWithDeliveryRegionsFragment } from '@/generated/graphql'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { enGB } from 'date-fns/locale'

export type DatepickerProps = {
  minDate?: Date
  forceClose?: boolean
  clearable?: boolean
  showErrors?: boolean
  showAllDates?: boolean
  showChevron?: boolean
  dateValue?: number
  availableVendorLocations: MenuVendorLocationWithDeliveryRegionsFragment[]
  disabled?: boolean
  error?: string
  onChange: (newDate: number) => void
  onClear?: () => void
}

const DatePickerInput: React.FC<DatepickerProps> = ({
  minDate,
  showErrors = true,
  showAllDates = false,
  dateValue,
  availableVendorLocations,
  error,
  onClear,
  onChange,
}) => {
  const locationsToUse = showAllDates ? [] : availableVendorLocations

  const handleChange = (newDate: Date) => {
    onChange(newDate.getTime())
  }

  const closedWeekdays = ((locationsToUse || [])[0]?.closingDates?.closedWeekdays || []).filter(
    (weekday) =>
      locationsToUse?.every((location) => location.closingDates?.closedWeekdays?.includes(weekday)),
  )
  const closedDates = ((locationsToUse || [])[0]?.closingDates?.closedDates || []).filter((date) =>
    locationsToUse?.every((location) => location.closingDates?.closedDates?.includes(date)),
  )

  const cutOff5pm = useFeatureFlag(SPLITS.GM_CUTOFF_5PM)
  const maxDateAllowed = moment().add(4, 'months').toDate()
  const minDateAllowed =
    minDate || getMinDateAllowed(locationsToUse, { date: dateValue }, cutOff5pm)

  const filterDates = (date: Date) => {
    if (closedWeekdays.find((day) => day === getDay(date))) return false
    return !dateIsInDates(date, closedDates)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <DatePicker
        onChange={(date) => (date ? handleChange(date) : null)}
        value={dateValue ? new Date(dateValue) : null}
        minDate={minDateAllowed}
        maxDate={maxDateAllowed}
        shouldDisableDate={(date) => !filterDates(date)}
        desktopModeMediaQuery={'@media (min-height: 650px'}
        slotProps={{
          textField: {
            error: showErrors,
            helperText: showErrors ? error : '',
            fullWidth: true,
            size: 'small',
            sx: {
              '& .MuiIconButton-root': {
                color: 'primary.main',
              },
            },
          },
        }}
      />

      {onClear && dateValue && (
        <CloseButton>
          <IconButton
            color="warning"
            size="small"
            onClick={() => {
              onClear()
            }}
            sx={{ marginRight: -1, marginTop: 0.7 }}
          >
            <CloseRounded />
          </IconButton>
        </CloseButton>
      )}
    </LocalizationProvider>
  )
}

export default DatePickerInput
