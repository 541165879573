import React, { useEffect, useRef, useState } from 'react'
import { AppProps } from 'next/app'
import { apolloInit } from '@/helpers/apollo/ApolloClient'
import { GetStaticPaths, GetStaticProps } from 'next'
import { match } from 'ts-pattern'
import { compact, sortBy } from 'lodash'
import styled from 'styled-components'
import tenants from '@/generated/tenants.json'
import { get } from '@vercel/edge-config'

import { mobileMediaQuery } from '@caterdesk/ui--theme'
import Box from '@caterdesk/ui--box'
import { Text, TitleSmall } from '@caterdesk/ui--typography'

import Navigation from '@/components/page-specific/gm/components/navigation'
import VendorHeader from '@/components/page-specific/gm/components/vendor-header'
import SideBarFilters from '@/components/page-specific/gm/components/side-bar-filters'
import MobileFilters from '@/components/page-specific/gm/components/side-bar-filters/mobile'
import InputBar from '@/components/page-specific/gm/components/input-bar'
import { BasketDrawer } from '@/components/page-specific/gm/components/basket-drawer'
import {
  LoadedMenu,
  useMenuPageState,
} from '@/components/page-specific/gm/components/states/menu-page'
import { MenuItemsSection } from '@/components/page-specific/gm/components/menu/menu-items-section'
import UnauthenticatedModal from '@/components/page-specific/gm/components/unauthenticated-modal'
import { MenuItemModal } from '@/components/page-specific/gm/components/menu-item-card/menu-item-modal'
import { withRouter } from '@/components/page-specific/gm/utils/withRouter'
import { useAppError } from '@/components/page-specific/gm/utils/errors'
import {
  BasketFragment,
  BasketItemFragment,
  GetVendorQuery,
  GetVendorQueryVariables,
  GetVendorDocument,
  GetVendorAvailabilityQuery,
  GetVendorAvailabilityQueryVariables,
  GetVendorAvailabilityDocument,
  TenantFromPrebuildFragment,
} from '@/generated/graphql'
import { MenuItem } from '@/components/page-specific/gm/domain/menu-item'
import Toast from '@/components/page-specific/gm/components/toast'
import {
  AddressErrors,
  BasketErrors,
  DateErrors,
  TimeErrors,
} from '@/components/page-specific/gm/types/enums/Errors.enum'
import { useAppState } from '@/components/page-specific/gm/components/states/app'
import { validateUserBasketSettings } from '@/components/page-specific/gm/domain/user-basket-settings'
import {
  Button,
  Stack,
  Box as MuiBox,
  Snackbar,
  AlertTitle,
  Alert,
  Grid as MuiGrid,
  useTheme,
  useMediaQuery,
  Container,
  Dialog,
  DialogContent,
} from '@mui/material'
import withGm from '@/components/page-specific/gm/components/withGm'
import { useRouter } from '@/components/Link'
import LoadingIndicator from '@/components/LoadingIndicator'
import Head from 'next/head'
import { getCurrencySymbol } from '@caterdesk/utils--money'
import { useSearchParams } from 'next/navigation'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'
import { ItemView } from '@/components/page-specific/vp/legacy/shared/components/layouts'
import { useExistingOrder } from '@/components/page-specific/gm/components/states/useExistingOrder'
import OrderDrawer from '@/components/page-specific/gm/components/order-drawer'
import { useOrderDrawerState } from '@/components/page-specific/gm/components/order-drawer/useOrderDrawerState'
import { getTenantFromDomain } from '@/helpers/tenants'
import { useInView } from 'react-intersection-observer'
import useGmFiltersStore, {
  GmFiltersStoreProvider,
} from '@/components/page-specific/gm/helpers/gmFiltersStore'
import { EVERYTHING_FILTER_CATEGORY } from '@/components/page-specific/gm/domain/menu-category-filter'
import { SUPPORTED_LOCALES } from '@/constants/languages'

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;

  @media ${mobileMediaQuery} {
    flex-direction: column-reverse;
  }
`

type Props = {
  vendorDetails: GetVendorQuery['vendor']
  itemData?: GetVendorAvailabilityQuery
  domain: string
  lang: string
  useServerSideItems?: boolean
  useVirtualisedList?: boolean
}

const MenuPage: React.FC<Props> = ({
  vendorDetails,
  domain,
  lang,
  itemData,
  useServerSideItems,
  useVirtualisedList,
}) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const theme = useTheme()
  const orderIdParam = searchParams?.get('orderId') || ''
  const standingOrderIdParam = searchParams?.get('standingOrderId') || ''
  const isEditingExistingOrder = Boolean(orderIdParam)
  const IrelandIsLive = useFeatureFlag(SPLITS.IRELAND_IS_LIVE)
  const appState = useAppState()
  const {
    itemName,
    selectedCategory,
    initialised,
    setItemName,
    setSelectedCategory,
    clearFilters,
    initialiseFilters,
  } = useGmFiltersStore()
  const pageState = useMenuPageState({
    vendorDetails,
    disableBasketFetching: !router.isReady || isEditingExistingOrder,
    itemData,
    useServerSideItems,
  })
  const editingStandingOrderTemplate = Boolean(
    pageState.menu.type === 'loaded' && pageState.menu.standingOrderData,
  )
  const { basketDesignedUiCompatibleOrderData, ...existingOrderState } = useExistingOrder({
    orderId: orderIdParam,
    vendorId: vendorDetails?.id,
    onSuccessfulSave: ({ orderId }) => {
      router.push(`/confirmation/order/gm?orderId=${orderId}`)
    },
  })

  const orderSelectedSettings = {
    ...(basketDesignedUiCompatibleOrderData || appState.userState),
    weekdays:
      pageState.menu.type === 'loaded'
        ? (pageState.menu.standingOrderData?.daysOfOccurrence ?? [])
        : [],
  }

  const { ref: loadMoreRef, entry } = useInView()

  const { updateUserState } = appState
  const [loginModal, toggleLoginModal] = useState(false)
  const { showOrderDrawer, toggleOrderDrawer } = useOrderDrawerState({
    orderId: existingOrderState.orderData?.id,
  })
  const [editItemModal, setEditItemModal] = useState<
    | {
        type: 'displayed'
        basketItem: Pick<
          BasketItemFragment,
          'itemReference' | 'item' | 'qty' | 'acknowledgedAt' | 'note' | 'options'
        >
        menuItem: MenuItem
      }
    | { type: 'hidden' }
  >({ type: 'hidden' })
  const [showQuickAddSnackbar, setShowQuickAddSnackbar] = useState<boolean>(false)
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const allowPersistantDrawer = useMediaQuery(theme.breakpoints.up('lg'))
  const isLargeBreakpoint = useMediaQuery(theme.breakpoints.between('lg', 'xl'))
  const { throwAppError } = useAppError()

  const goalsParam = searchParams?.get('goals')
  const dietariesParam = searchParams?.get('dietaries')
  const allergensParam = searchParams?.get('allergens')
  const itemNameParam = searchParams?.get('itemName')

  const loadMore = pageState.menu.type === 'loaded' ? pageState.menu.loadMore : null
  const loadingMore = pageState.menu.type === 'loaded' ? pageState.menu.loadingMore : null
  const intersectionTime = useRef<number>(0)

  const setCategoryParams = (categoryParam: string) => {
    if (pageState.menu.type !== 'loaded') return
    const { availability } = pageState.menu
    const category = availability.filterCategories.find((c) => c.name === categoryParam)
    if (!category) return
    setSelectedCategory(category)
  }

  useEffect(() => {
    if (initialised || !router.isReady || pageState.menu.type !== 'loaded' || !searchParams) return

    const { availability } = pageState.menu
    const category = availability.filterCategories.find(
      (c) => c.name === searchParams?.get('category'),
    )

    const goals =
      availability?.filterSections.goals.filter((g) => goalsParam?.split(',')?.includes(g.value)) ||
      []
    const dietaries =
      availability?.filterSections.dietaries.filter((g) =>
        dietariesParam?.split(',')?.includes(g.value),
      ) || []
    const allergens =
      availability?.filterSections.allergens.filter((g) =>
        allergensParam?.split(',')?.includes(g.value),
      ) || []

    initialiseFilters({
      itemName: itemNameParam || '',
      category: category || EVERYTHING_FILTER_CATEGORY,
      goals,
      dietaries,
      allergens,
    })
  }, [searchParams, router.isReady, pageState.menu.type, initialised])

  useEffect(() => {
    if (
      !entry?.isIntersecting ||
      entry.time === intersectionTime.current ||
      loadingMore ||
      !loadMore ||
      (pageState.menu.type === 'loaded' && pageState.menu.availability.menuCategories.length < 1)
    )
      return
    intersectionTime.current = entry.time
    loadMore()
  }, [entry, pageState.menu.type, loadMore, loadingMore])

  useEffect(() => {
    if (!router.isReady) return
    if (orderIdParam || standingOrderIdParam || editingStandingOrderTemplate) return
    if (pageState.type !== 'loaded') return
    if (
      appState.errors.time === TimeErrors.TIME_PAST ||
      appState.errors.date === DateErrors.DATE_PAST ||
      appState.errors.date === DateErrors.INVALID_DATE ||
      appState.errors.time === TimeErrors.INVALID_TIME ||
      appState.errors.address === AddressErrors.VENDER_COVERAGE
    ) {
      pageState.orderDetailsState.setOrderDetailsModalOpen(true)
    }
  }, [
    appState.errors,
    pageState.type,
    orderIdParam,
    standingOrderIdParam,
    router.isReady,
    editingStandingOrderTemplate,
  ])

  const {
    vendor,
    selectedVendorLocation,
    orderDetailsState,
    basketError,
    minOrderValue,
    minOrderValueExTax,
  } = pageState
  const allowsCountry = vendor.locations.findIndex((location) => {
    let countryLang = location?.country?.toLowerCase()
    if (!IrelandIsLive && countryLang === 'ie') countryLang = 'gb'
    return countryLang === lang.split('-')[1].toLowerCase()
  })
  const sortedImages = sortBy(vendor.images, (i) => i?.position)
  const vendorImages = compact(sortedImages.map((image) => image?.secureUrl))

  useEffect(() => {
    updateUserState({
      vendor: {
        imageUrl: vendorImages[0],
        url: `https://${domain}/${lang}/office-catering/vendors/${vendor.permalink}`,
      },
    })
  }, [domain, lang, updateUserState, vendor.permalink, vendorImages])

  if (
    IrelandIsLive &&
    allowsCountry < 0 &&
    router.isReady &&
    lang === 'en-gb' &&
    vendor.locations[0]?.country?.toLowerCase() === 'ie'
  ) {
    router.push(`/en-ie/office-catering/vendors/${vendor.permalink}`)
    return null
  }
  if (allowsCountry < 0 && router.isReady) router.push('/office-catering/vendors')

  const minimumNoticePeriod = selectedVendorLocation?.details?.gmMinNoticePeriod

  const toggleItemView = (display: ItemView) => {
    pageState.menu.type === 'loaded' && pageState.menu.toggleItemView(display)
  }

  const openEditItemModal = (
    basket: BasketFragment,
    basketItem: Pick<
      BasketItemFragment,
      'itemReference' | 'item' | 'qty' | 'acknowledgedAt' | 'note' | 'options'
    >,
    menuItems: MenuItem[],
  ) => {
    const menuItem = menuItems.find((i) => i._id === basketItem.item?._id)
    if (!menuItem)
      return throwAppError({
        type: 'COULD_NOT_FIND_MENU_ITEM_OF_BASKET_ITEM',
        basketId: basket.id,
        itemReference: basketItem.itemReference,
      })
    setEditItemModal({ type: 'displayed', basketItem, menuItem })
  }

  const checkoutCallback = (basketId: string) => {
    const validated = appState.validate()
    if (validated) {
      if (pageState.menu.type === 'loaded') {
        const userBasketSettings = orderDetailsState
        const usersValidatedState = validateUserBasketSettings(userBasketSettings)
        if (usersValidatedState.type === 'success')
          pageState.menu.handleUserBasketSettingsConfirm(usersValidatedState.value)
      }
      router.push(`/office-catering/checkout/?id=${basketId}`)
    } else {
      orderDetailsState.setOrderDetailsModalOpen(true)
    }
  }

  const createNewDraft = (menu: Omit<LoadedMenu, 'basket'>) => {
    menu.clearBasket()
    menu.setDraftModalIsOpen(false)
  }

  const setItemNameParam = (itemName: string) => {
    if (!initialised) return
    setItemName(itemName)
  }

  const ModalButtonGroup = (menu: Omit<LoadedMenu, 'basket'>) => (
    <ButtonGroup>
      <Button variant="outlined" fullWidth onClick={menu.keepDraft}>
        Keep
      </Button>
      <Button variant="contained" fullWidth onClick={() => createNewDraft(menu)}>
        Create new draft
      </Button>
    </ButtonGroup>
  )

  const openBasket = () => {
    if (pageState.menu.type === 'loaded') {
      pageState.menu.toggleBasketIsOpen(true)
      setShowQuickAddSnackbar(false)
    }
  }

  const useMargin =
    pageState.menu.type === 'loaded' &&
    (pageState.menu.basketIsOpen || showOrderDrawer) &&
    allowPersistantDrawer

  const taxAcronym = pageState?.taxAcronym

  const handleClearAll = () => {
    clearFilters()
  }

  return (
    <>
      <Navigation
        onClickBasketPreview={() => {
          if (isEditingExistingOrder) {
            toggleOrderDrawer(true)
          } else if (pageState.menu.type === 'loaded' && pageState.menu.basket) {
            pageState.menu.basket.itemCount > 0 && pageState.menu.toggleBasketIsOpen(true)
          }
        }}
        existingOrderData={
          existingOrderState.orderData
            ? {
                itemQuantity: existingOrderState.itemQuantity,
                subtotalExTax: existingOrderState.grandTotals.subtotalExTax,
                currency: vendor.currency,
              }
            : null
        }
        basketIsOpen={useMargin}
        newItemWasJustAddedToBasket={existingOrderState.newItemWasJustAdded}
      />
      <Container>
        <MuiBox
          sx={{
            transition: 'margin-right 0.5s ease-in-out',
          }}
        >
          <Head>
            <title>{`${vendor.companyName} Menu | Office Catering`}</title>
            <meta name="title" content={`${vendor.companyName} Menu | Office Catering`} />
            <meta
              name="keywords"
              content={
                vendor.keywords ||
                `${vendor.companyName} menu, ${vendor.companyName} office catering`
              }
            />
            <meta
              name="description"
              content={
                vendor.descriptionShort ||
                'Corporate catering for all occasions from breakfast buffets and sandwich platters to salad boxes and sushi. Served straight to the office, just how you like it.'
              }
            />
            <link
              rel="canonical"
              href={`https://${domain}/${lang}/office-catering/vendors/${vendor.permalink}`}
            />
            {vendor.rating && vendor.rating >= 3 && (
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: `
                  {
                    "@context": "https://schema.org/",
                    "@type": "AggregateRating",
                    "itemReviewed": {
                      "@type": "Restaurant",
                      "image": "${vendor.logo}",
                      "name": "${vendor.companyName}",
                      "servesCuisine": ["${vendor.keywords?.replace(/,( +)?/g, '", "')}"],
                      "priceRange": "${Array.from({ length: vendor.priceLevel || 1 })
                        .map(() => getCurrencySymbol(vendor.currency))
                        .join('')}"
                    },
                    "ratingValue": "${vendor.rating}",
                    "bestRating": "4",
                    "ratingCount": "${vendor.totalRatings}"
                  }
                }`,
                }}
              />
            )}
          </Head>
          {basketError && (
            <Toast message={`Ooops! We ${BasketErrors[basketError]}, please refresh the page`} />
          )}
          {isMobileScreen &&
            match(pageState.menu)
              .with({ type: 'loading' }, () => null)
              .with({ type: 'loaded' }, (menu) => (
                <MuiBox sx={{ paddingX: 3, paddingY: 1 }}>
                  <InputBar
                    disabled={isEditingExistingOrder || editingStandingOrderTemplate}
                    hideFields={{ date: editingStandingOrderTemplate }}
                    orderSelectedSettings={orderSelectedSettings}
                    onValueChange={(values) => appState.updateUserState(values)}
                    availableVendorLocations={appState.availableVendorLocations}
                    vendorId={vendor.id}
                    toggleItemView={toggleItemView}
                    modalOpen={orderDetailsState.orderDetailsModalOpen}
                    modalIsLoading={menu.basketIsUpdating}
                    setModalOpen={orderDetailsState.setOrderDetailsModalOpen}
                    onConfirm={(newState) => {
                      appState.updateUserState(newState)
                      menu.handleUserBasketSettingsConfirm(newState)
                    }}
                  />
                </MuiBox>
              ))
              .exhaustive()}
          <VendorHeader
            images={vendorImages}
            logo={vendor.logo}
            title={vendor.companyName}
            keywords={vendor.keywords}
            rating={vendorDetails?.ratingScaled}
            totalRatings={vendor.totalRatings}
            currency={vendor.currency}
            minimumOrderValue={minOrderValue}
            minimumOrderValueExTax={minOrderValueExTax}
            minimumNoticePeriod={minimumNoticePeriod}
            descriptionFull={vendor.descriptionFull}
            quote={vendor.quote}
            sourcing={vendor.sourcing}
            taxAcronym={taxAcronym}
            externalUrlData={vendor.externalUrlData}
          />
          <MuiBox padding={1} sx={{ borderColor: 'divider' }} borderTop={1} borderBottom={1}>
            {match(pageState.menu)
              .with({ type: 'loading' }, () => null)
              .with({ type: 'loaded' }, (menu) => (
                <>
                  {isMobileScreen ? (
                    <MobileFilters
                      categories={menu.availability.filterCategories}
                      filterSections={menu.availability.filterSections}
                      selectedCategory={selectedCategory}
                      onCategoryChange={setCategoryParams}
                    />
                  ) : (
                    <InputBar
                      disabled={isEditingExistingOrder || editingStandingOrderTemplate}
                      orderSelectedSettings={orderSelectedSettings}
                      hideFields={{ date: editingStandingOrderTemplate }}
                      onValueChange={(values) => appState.updateUserState(values)}
                      availableVendorLocations={appState.availableVendorLocations}
                      vendorId={vendor.id}
                      toggleItemView={toggleItemView}
                      modalOpen={orderDetailsState.orderDetailsModalOpen}
                      modalIsLoading={menu.basketIsUpdating}
                      setModalOpen={orderDetailsState.setOrderDetailsModalOpen}
                      onConfirm={(newState) => {
                        appState.updateUserState(newState)
                        menu.handleUserBasketSettingsConfirm(newState)
                      }}
                    />
                  )}
                </>
              ))
              .exhaustive()}
          </MuiBox>
          <Box backgroundColor="background_light" padding={{ y: 24, x: 8 }}>
            {match(pageState.menu)
              .with({ type: 'loading' }, () => (
                <Stack alignItems="center" height="60vh">
                  <LoadingIndicator />
                </Stack>
              ))
              .with({ type: 'loaded' }, ({ basket, ...menu }) => (
                <>
                  {loginModal && basket && (
                    <UnauthenticatedModal
                      closeModal={() => toggleLoginModal(false)}
                      basketId={basket.id}
                    />
                  )}
                  <MuiGrid container spacing={1}>
                    {!isMobileScreen && (
                      <MuiGrid item width={300} xs={12} sm={12} xl={12}>
                        <Stack alignItems="flex-end">
                          <Button size="small" variant="text" onClick={handleClearAll}>
                            Clear all
                          </Button>
                        </Stack>
                        <SideBarFilters
                          asRow
                          categories={menu.availability.filterCategories}
                          filterSections={menu.availability.filterSections}
                          selectedCategory={selectedCategory}
                          onCategoryChange={setCategoryParams}
                          itemNameSearchValue={itemName}
                          onItemNameChange={setItemNameParam}
                        />
                      </MuiGrid>
                    )}
                    <MuiGrid item xs={12} sm={12} xl={12}>
                      {match(pageState.menu)
                        .with({ type: 'loading' }, () => null)
                        .with({ type: 'loaded' }, () => (
                          <>
                            <MenuItemsSection
                              display={
                                useMargin && isLargeBreakpoint ? ItemView.List : menu.itemView
                              }
                              taxAcronym={pageState.taxAcronym}
                              showPricesExTax={pageState.showPricesExTax}
                              vendor={vendor}
                              categories={menu.availability.menuCategories}
                              onAddItemToBasketClick={(item) => {
                                if (isEditingExistingOrder) {
                                  existingOrderState.addNewItemToOrder({
                                    liveItemId: item.item._id,
                                    variationId: item.item.variationId,
                                    name: item.item.name,
                                    priceExTax: item.item.priceCustomerFacingExTax,
                                    priceIncTax: item.item.priceCustomerFacing,
                                    qty: item.qty,
                                    taxRate: item.item.taxRate,
                                    note: item.note ?? undefined,
                                    options:
                                      item.options?.map((option) => ({
                                        id: option.optionItem._id,
                                        name: option.optionItem.item.name,
                                        optionCategoryId: option.option._id,
                                        priceExTax: option.optionItem.priceCustomerFacingExTax ?? 0,
                                        priceIncTax: option.optionItem.priceCustomerFacing,
                                        qty: option.qty * item.qty,
                                        taxRate: option.optionItem.taxRate,
                                      })) ?? [],
                                  })
                                } else {
                                  menu.addItemToBasket(item)
                                }
                                if (
                                  (menu.basketClosedByUser && !menu.basketIsOpen) ||
                                  (!showOrderDrawer && existingOrderState.itemQuantity > 0)
                                )
                                  setShowQuickAddSnackbar(true)
                              }}
                              basketIsLoading={menu.basketIsUpdating}
                              closeModalWithoutLoading={!!basket}
                              loadingMore={Boolean(loadingMore)}
                              useVirtualisedList={useVirtualisedList}
                            />
                            {!useServerSideItems &&
                            !loadingMore &&
                            pageState.menu.type === 'loaded' &&
                            pageState.menu.hasMore ? (
                              <div
                                style={{ position: 'relative', top: '-2000px' }}
                                ref={loadMoreRef}
                              />
                            ) : null}
                          </>
                        ))
                        .exhaustive()}
                      {existingOrderState.orderData ? (
                        <OrderDrawer
                          isPersistentDrawer={allowPersistantDrawer}
                          saving={existingOrderState.saving}
                          onSaveChanges={existingOrderState.saveOrderChanges}
                          errors={{
                            ...existingOrderState.validationErrors,
                            ...(existingOrderState.saveError && {
                              SAVE_CHANGES_ERROR: { message: existingOrderState.saveError.message },
                            }),
                          }}
                          canSaveChanges={existingOrderState.canSaveChanges}
                          recalculatingFees={existingOrderState.recalculatingFees}
                          recalculatingTotals={existingOrderState.recalculatingTotals}
                          taxAcronym={pageState.taxAcronym}
                          onRemoveItem={existingOrderState.removeItemFromOrder}
                          onAdjustItemQuantity={existingOrderState.adjustItemQuantity}
                          fees={existingOrderState.fees}
                          taxRateTotals={existingOrderState.taxRateTotals}
                          grandTotals={existingOrderState.grandTotals}
                          orderItems={existingOrderState.orderItems}
                          orderData={existingOrderState.orderData}
                          open={showOrderDrawer}
                          onToggleDrawer={(value) => {
                            toggleOrderDrawer(value)
                          }}
                        />
                      ) : null}
                      {basket && (
                        <>
                          <BasketDrawer
                            standingOrderData={menu.standingOrderData}
                            isPersistentDrawer={allowPersistantDrawer}
                            isOpen={menu.basketIsOpen}
                            showPricesExTax={pageState.showPricesExTax}
                            taxAcronym={pageState.taxAcronym}
                            basket={basket}
                            basketIsUpdating={menu.basketIsUpdating}
                            clearLocalBasket={menu.clearBasket}
                            currency={vendor.currency}
                            minOrderValue={minOrderValue}
                            minOrderValueExTax={minOrderValueExTax}
                            closeBasket={menu.handleBasketClosedByUser}
                            setOrderDetailsModalOpen={orderDetailsState.setOrderDetailsModalOpen}
                            onNonNumberedItemQuantityChange={(item, qty) =>
                              menu.updateItemQty(basket, item, qty)
                            }
                            onNumberedItemQuantityChange={(basketItem) =>
                              openEditItemModal(basket, basketItem, menu.availability.items)
                            }
                            acknowledgeEditedItems={(items) =>
                              items.map((item) =>
                                menu.updateItemInBasket(basket, item, {
                                  acknowledgedAt: new Date(),
                                }),
                              )
                            }
                            removeInvalidItemsFromBasket={(items) =>
                              items.map((item) => menu.removeItemFromBasket(basket, item))
                            }
                            onDeleteClick={menu.removeItemFromBasket}
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            checkoutCallback={checkoutCallback}
                          />
                          {editItemModal.type === 'displayed' && (
                            <MenuItemModal
                              showPricesExTax={pageState.showPricesExTax}
                              taxAcronym={pageState.taxAcronym}
                              mode="edit"
                              menuItem={editItemModal.menuItem}
                              basketItem={editItemModal.basketItem}
                              vendor={vendor}
                              onConfirmClick={({ qty, options }) => {
                                const basketInput = { qty, options }
                                menu.updateItemInBasket(
                                  basket,
                                  editItemModal.basketItem,
                                  basketInput,
                                )
                              }}
                              onClose={() => setEditItemModal({ type: 'hidden' })}
                              basketIsLoading={menu.basketIsUpdating}
                              closeWithoutLoading={true}
                            />
                          )}
                        </>
                      )}
                    </MuiGrid>
                  </MuiGrid>
                  {basket && menu.draftModalIsOpen && (
                    <Dialog open onClose={menu.keepDraft}>
                      <DialogContent
                        sx={{
                          py: 4,
                        }}
                      >
                        <Box>
                          <TitleSmall style={{ textAlign: 'center', fontWeight: 600 }}>
                            Create new draft?
                          </TitleSmall>
                          <Text style={{ textAlign: 'center' }}>
                            You’re currently viewing draft ID {basket?.friendlyId}. You can keep
                            using this draft or create a new one.
                          </Text>
                        </Box>
                        <MuiBox mt={2}>{ModalButtonGroup(menu)}</MuiBox>
                      </DialogContent>
                    </Dialog>
                  )}
                  <Snackbar
                    open={showQuickAddSnackbar && !menu.basketIsOpen}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={2000}
                    sx={{ marginBottom: '60px' }}
                    onClose={(_e: React.SyntheticEvent | Event, reason?: string) => {
                      if (reason === 'clickaway') {
                        return
                      }
                      setShowQuickAddSnackbar(false)
                    }}
                  >
                    <Alert severity="success" variant="filled" sx={{ zIndex: 4 }}>
                      <AlertTitle sx={{ marginBottom: 0 }}>
                        {'Item(s) added successfully'}
                      </AlertTitle>
                      <Stack direction="row-reverse">
                        <Button variant="outlined" size="small" onClick={openBasket}>
                          View basket
                        </Button>
                      </Stack>
                    </Alert>
                  </Snackbar>
                </>
              ))
              .exhaustive()}
          </Box>
        </MuiBox>
      </Container>
    </>
  )
}

// eslint-disable-next-line react/display-name
const withFilters = (Component: React.FC) => (props: any) => {
  return (
    <GmFiltersStoreProvider useServerSideItems={props.useServerSideItems}>
      <Component {...props} />
    </GmFiltersStoreProvider>
  )
}

// @ts-expect-error unknown
const Component: React.FC<AppProps> = withRouter(withFilters(withGm(MenuPage, false)))
Component.displayName = 'GM'

type Params = {
  permalink: string
  lang: string
  domain: string
}

export const getStaticPaths: GetStaticPaths<Params> = () => {
  const paths = (tenants as TenantFromPrebuildFragment[]).reduce<{ params: Params }[]>(
    (acc, tenant) => {
      const permalinks = tenant.features?.prerenderMarketplaceMenu?.permalinks || []
      if (permalinks.length === 0) return acc

      const validLocales = SUPPORTED_LOCALES.filter((locale) =>
        tenant.countries.some((country) => locale.endsWith(`-${country}`)),
      )

      validLocales.forEach((locale) => {
        permalinks.forEach((permalink) => {
          acc.push({
            params: {
              domain: tenant.domain,
              lang: locale,
              permalink,
            },
          })
        })
      })

      return acc
    },
    [],
  )

  return {
    paths,
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps<
  { vendorDetails?: GetVendorQuery['vendor'] },
  Params
> = async ({ params }) => {
  if (!params) throw new Error('Params not found in getStaticProps')
  const tenant = getTenantFromDomain(params.domain)
  if (!tenant) throw new Error('Tenant not found in getStaticProps')
  const apolloClient = apolloInit()

  let useServerSideItems = false
  let useVirtualisedList = false
  if (process.env.EDGE_CONFIG) {
    const ssrVendors = (await get('ssrVendors')) as {
      domain: string
      permalinks: string[]
    }[]

    if (
      ssrVendors.some(
        (vendor) => vendor.domain === params.domain && vendor.permalinks.includes(params.permalink),
      )
    ) {
      useServerSideItems = true
    }

    const virtualisedListVendors = (await get('virtualisedListVendors')) as {
      domain: string
      permalinks: string[]
    }[]

    if (
      virtualisedListVendors.some(
        (vendor) => vendor.domain === params.domain && vendor.permalinks.includes(params.permalink),
      )
    ) {
      useVirtualisedList = true
    }
  }

  const { data } = await apolloClient.query<GetVendorQuery, GetVendorQueryVariables>({
    query: GetVendorDocument,
    variables: { permalink: params.permalink, maxRating: 5, tenantId: tenant.id },
  })

  if (!useServerSideItems) {
    return {
      props: {
        ssg: 1,
        vendorDetails: data?.vendor,
        domain: params.domain,
        lang: params.lang,
      },
      revalidate: 60 * 60 * 2, // 2 hours
    }
  }

  if (!data?.vendor) {
    throw new Error('Vendor not found')
  }

  const sortedCategoryIds = [...data.vendor.categories]
    .sort((a, b) => {
      if (!a || a?.position === null) return 1
      if (!b || b?.position === null) return -1
      return a?.position - b?.position
    })
    .map((category) => (category ? Number(category.id) : null))
    .filter((id) => id !== null)

  const { data: itemData } = await apolloClient.query<
    GetVendorAvailabilityQuery,
    GetVendorAvailabilityQueryVariables
  >({
    query: GetVendorAvailabilityDocument,
    variables: {
      vendorId: data?.vendor.id,
      limit: 10000,
      offset: 0,
      filters: {},
      sorting: {
        categorySortPriority: sortedCategoryIds,
      },
    },
  })

  return {
    props: {
      ssg: 1,
      vendorDetails: data?.vendor,
      domain: params.domain,
      lang: params.lang,
      itemData: itemData,
      useServerSideItems,
      useVirtualisedList,
    },
    revalidate: 60 * 60 * 2, // 2 hours
  }
}

export default Component
