import React, { useEffect, useMemo, useState } from 'react'
import { useMedia } from 'react-use'
import { match } from 'ts-pattern'
import { amountToPriceString } from '@caterdesk/utils--money'
import { calculateItemSubtotal } from '@caterdesk/utils--basket'
import {
  SelectedOptionItem as SelectedOptionItemValidation,
  getInitiallySelectedOptionItems,
  reachedMinimumOptionRequirements,
} from '@caterdesk/utils--gm-validation'
import NumberPicker from '@caterdesk/ui--number-picker'
import { mobileMediaQuery } from '@caterdesk/ui--theme'
import { OverviewTab } from './overview-tab'
import { IngredientsTab } from './ingredients-tab'
import { NutritionalTab } from './nutritional-tab'
import { SourcingTab } from './sourcing-tab'
import { CaptionLogo, CaptionText } from './menu-item-modal.styles'
import { StickyModalSection } from './index.styles'
import { BasketItemFragment } from '@/generated/graphql'
import { MenuItemOption, MenuOptionItem } from '../../domain/menu-item-option'
import { MenuItem } from '../../domain/menu-item'
import { MenuVendor } from '../../domain/menu-vendor'
import { UpdateBasketItemPayload } from '../states/menu-page/update-item-mutation'
import { AddItemPayload } from '../states/menu-page/add-item-mutation'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  Tab as MuiTab,
  Stack,
  Tabs,
  Typography,
} from '@mui/material'
import { getImageUrl } from '../image'
import { Star } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'

export type Props = (
  | { mode: 'add'; onConfirmClick: (item: AddItemPayload) => void }
  | { mode: 'edit'; onConfirmClick: (item: UpdateBasketItemPayload) => void }
) & {
  menuItem: MenuItem
  basketItem?: BasketItemFragment
  vendor: MenuVendor
  basketIsLoading: boolean
  closeWithoutLoading: boolean
  onClose: () => void
  taxAcronym: string
  showPricesExTax: boolean
  initialQty?: number
  accountName?: string | undefined
}

const TABS = [
  { id: 'overview', label: 'Overview' },
  { id: 'ingredients', label: 'Ingredients' },
  { id: 'nutritional', label: 'Nutritional' },
  { id: 'sourcing', label: 'Sourcing' },
] as const
type Tab = (typeof TABS)[number]

export type SelectedOptionItem = {
  option: MenuItemOption
  item: MenuOptionItem
  quantity: number
}

export const MenuItemModal: React.FC<Props> = (props) => {
  const {
    menuItem,
    basketItem,
    vendor,
    onClose,
    basketIsLoading,
    closeWithoutLoading,
    initialQty,
    accountName,
  } = props
  const isMobileScreen = useMedia(mobileMediaQuery, false)
  const [activeTabId, setActiveTabId] = useState<Tab['id']>('overview')
  const [quantity, setQuantity] = useState(initialQty || basketItem?.qty || menuItem.minQty)
  const [note, setNote] = useState(basketItem?.note || '')
  const [loading, setLoading] = useState(false)
  const menuItemWithOptions = {
    options: menuItem.options as unknown as SelectedOptionItemValidation['option'][],
  }
  const [selectedOptions, setSelectedOptions] = useState<
    Map<MenuItemOption['_id'], Map<MenuOptionItem['_id'], SelectedOptionItem>>
  >(basketItem ? getInitiallySelectedOptionItems(menuItemWithOptions, basketItem) : new Map())
  const confirmButtonCopy = basketItem ? 'Update basket' : 'Add to basket'
  const taxCopy = props.showPricesExTax ? `(ex ${props.taxAcronym})` : ''
  // @ts-expect-error unknown
  const options = [...selectedOptions.values()]
    .flatMap((o) => [...o.values()])
    .map(({ option, item, quantity }) => ({
      price: item.price || 0,
      priceExTax: item.priceExTax || 0,
      qty: quantity || 1,
      type: option.optionType || undefined,
      item: item.item,
    }))

  const itemSubtotals = calculateItemSubtotal(
    {
      itemPrice: menuItem.price,
      itemPriceExTax: menuItem.priceExTax,
      itemTaxRate: menuItem.taxRate,
      itemTaxValue: menuItem.taxValue,
    },
    quantity,
    options,
  )

  const total = props.showPricesExTax ? itemSubtotals.subtotalExTax : itemSubtotals.subtotal

  const handleAddItem = () => {
    setLoading(true)

    // @ts-expect-error unknown
    const options = [...selectedOptions.values()]
      .flatMap((o) => [...o.values()])
      .map(({ option, item, quantity }) => ({ option, optionItem: item, qty: quantity }))

    match(props)
      .with({ mode: 'add' }, ({ onConfirmClick }) =>
        onConfirmClick({ item: menuItem, qty: quantity, note, options }),
      )
      .with({ mode: 'edit' }, ({ onConfirmClick }) => onConfirmClick({ qty: quantity, options }))
      .exhaustive()

    if (closeWithoutLoading) onClose()
  }

  useEffect(() => {
    if (loading && !basketIsLoading) {
      onClose()
    }
  }, [loading, basketIsLoading])

  const selectOptionItem = (option: MenuItemOption, item: MenuOptionItem, quantity?: number) => {
    const optionId = option._id
    const optionItemId = item._id
    const options = new Map(selectedOptions)
    const selectedOptionGroup = options.get(optionId)

    if (quantity === 0) {
      if (selectedOptionGroup) {
        const option = new Map(selectedOptionGroup)
        option.delete(optionItemId)
        options.set(optionId, option)
        if (option.size === 0) {
          options.delete(optionId)
        }
      }
    } else {
      const selectedOption: Map<string, SelectedOptionItem> = selectedOptionGroup
        ? new Map(selectedOptionGroup)
        : new Map()

      if (selectedOptionGroup) {
        if (quantity) {
          selectedOption.set(optionItemId, { option, item, quantity })
        } else {
          selectedOption.has(optionItemId)
            ? selectedOption.delete(optionItemId)
            : selectedOption.set(optionItemId, { option, item, quantity: 1 })
        }
      } else {
        selectedOption.set(optionItemId, { option, item, quantity: quantity || 1 })
      }

      options.set(optionId, selectedOption)
    }
    setSelectedOptions(options)
  }

  const reachedMinOptionRequirementsHandler = useMemo(() => {
    return reachedMinimumOptionRequirements({
      menuItem: {
        options: menuItem.options as unknown as SelectedOptionItemValidation['option'][],
      },
      selectedOptions: selectedOptions as unknown as Map<
        string,
        Map<string, SelectedOptionItemValidation>
      >,
      topQuantity: quantity,
    })
  }, [selectedOptions, quantity])

  const tabContents: Record<Tab['id'], React.ReactElement> = {
    overview: (
      <OverviewTab
        taxAcronym={props.taxAcronym}
        showPricesExTax={props.showPricesExTax}
        vendor={vendor}
        item={menuItem}
        quantity={quantity}
        selectedOptions={selectedOptions}
        selectOptionItem={selectOptionItem}
        onAllergyButtonClick={() => setActiveTabId('ingredients')}
        note={note}
        noteIsEditable={!basketItem}
        setNote={setNote}
      />
    ),
    ingredients: <IngredientsTab vendor={vendor} item={menuItem} />,
    nutritional: <NutritionalTab vendor={vendor} item={menuItem} />,
    sourcing: <SourcingTab vendor={vendor} />,
  }

  let servesAmount = menuItem.servings * quantity
  if (menuItem.isPricePerHead) servesAmount = quantity

  const bgImage = menuItem.image
    ? getImageUrl({ src: menuItem.image, width: 600, quality: 100 })
    : null

  return (
    <Box sx={{ zIndex: 1300, position: 'relative' }}>
      <Dialog
        open={true}
        fullWidth
        maxWidth="lg"
        onClose={onClose}
        fullScreen={isMobileScreen}
        sx={{
          '.MuiPaper-root': {
            height: { xs: '100%', sm: '60%' },
            minHeight: '500px',
          },
        }}
      >
        {isMobileScreen && (
          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
            py={1}
            px={3}
            alignItems="center"
          >
            <DialogTitle sx={{ p: 0 }}></DialogTitle>
            <IconButton color="inherit" onClick={onClose} aria-label="close" edge="end">
              <CloseIcon />
            </IconButton>
          </Stack>
        )}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              sm: bgImage ? '1fr 1fr' : '1fr',
            },
            overflow: 'hidden',
            height: '100%',
          }}
        >
          {bgImage && (
            <Box
              sx={{
                position: 'relative',
                backgroundImage: `url(${bgImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                position="absolute"
                bottom={0}
                p={2}
                width="100%"
                sx={{
                  background: 'linear-gradient(rgba(63, 65, 68, 0), rgba(63, 65, 68, 0.7))',
                  color: '#fff',
                }}
              >
                {vendor.logo && (
                  <CaptionLogo
                    src={getImageUrl({ src: vendor.logo, width: 50, quality: 100 })}
                    alt="Vendor logo"
                  />
                )}
                <CaptionText>Freshly prepared by {vendor.companyName}</CaptionText>
              </Stack>
            </Box>
          )}
          <Stack direction="column" overflow="hidden">
            {!isMobileScreen && (
              <Typography variant="h5" p={2} pb={0}>
                {menuItem.name}
              </Typography>
            )}
            {menuItem.isPrivate && (
              <Chip
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Star sx={{ marginRight: 0.5 }} />
                    {`Private - ${accountName}`}
                  </Box>
                }
                sx={{
                  position: 'absolute',
                  top: 20,
                  left: '20%',
                  transform: 'translateX(-20%)',
                  backgroundColor: 'primary.light',
                }}
                color="primary"
                size="medium"
              />
            )}
            <Box
              sx={{
                overflowY: 'auto',
                flex: '1 1 0%',
              }}
            >
              <StickyModalSection>
                <Tabs
                  value={activeTabId}
                  onChange={(_, value) => setActiveTabId(value)}
                  variant="fullWidth"
                >
                  {TABS.map((tab) => (
                    <MuiTab key={tab.id} label={tab.label} value={tab.id} />
                  ))}
                </Tabs>
              </StickyModalSection>
              {tabContents[activeTabId]}
            </Box>
            <Box
              sx={{
                p: 2,
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 30px',
              }}
            >
              <Stack direction="row" gap={2}>
                <NumberPicker
                  value={quantity}
                  min={menuItem.minQty}
                  max={2000}
                  onChange={setQuantity}
                />
                {match(isMobileScreen)
                  .with(true, () => (
                    <LoadingButton
                      variant="contained"
                      size="large"
                      fullWidth
                      disabled={!reachedMinOptionRequirementsHandler}
                      onClick={handleAddItem}
                      loading={loading}
                    >
                      {confirmButtonCopy} ({amountToPriceString(vendor.currency, total, true)}){' '}
                      {taxCopy}
                    </LoadingButton>
                  ))
                  .with(false, () => (
                    <LoadingButton
                      variant="contained"
                      fullWidth
                      disabled={!reachedMinOptionRequirementsHandler}
                      onClick={handleAddItem}
                      loading={loading}
                    >
                      <Stack direction="row" justifyContent="center" gap={1} flexWrap="wrap">
                        <span>{confirmButtonCopy}</span>
                        <span>
                          {amountToPriceString(vendor.currency, total, true)} {taxCopy} (Serves{' '}
                          {servesAmount})
                        </span>
                      </Stack>
                    </LoadingButton>
                  ))
                  .exhaustive()}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Dialog>
    </Box>
  )
}
