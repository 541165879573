import React from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { CalendarToday, Close, Favorite, Info } from '@mui/icons-material'
import { FragmentType, graphql, useFragment } from '@/generated/gql'
import { CustomerFacingExistingGmOrderItem } from '../states/useExistingOrder/useExistingOrderItems'
import ItemCard from './ItemCard'
import { Currency, getCurrencySymbol } from '@caterdesk/utils--money'
import ChargeRow from './ChargeRow'
import { PaymentMethod } from '@/generated/gql/graphql'
import useCharityMeals from '@/helpers/features/charityMeals'
import { format } from 'date-fns'

const gmOrderDrawerOrderDataFragment = graphql(`
  fragment GmOrderDrawer_OrderDataFragment on GmOrder {
    orderId
    deliveryDate
    deliveryTimeSlot
    hasEnterpriseDiscount
    headCount
    vendor {
      currency
    }
  }
`)

type Props = {
  open: boolean
  orderData: FragmentType<typeof gmOrderDrawerOrderDataFragment>
  orderItems: Array<CustomerFacingExistingGmOrderItem>
  taxAcronym: string
  canSaveChanges: boolean
  recalculatingTotals?: boolean
  recalculatingFees?: boolean
  saving?: boolean
  isPersistentDrawer?: boolean
  errors: Partial<{
    SAVE_CHANGES_ERROR: { message: string }
    PAST_EDIT_CUT_OFF: { cutOffTime: Date }
    MINIMUM_SPEND_NOT_MET: { minimumOrderValue: string; amountRequired: string }
    INVALID_PAYMENT_METHOD: { paymentMethod: PaymentMethod }
  }>
  fees: Array<{
    description: string
    totalExTax: string
  }>
  taxRateTotals: Array<{
    rate: string
    value: string
  }>
  grandTotals: {
    discountExTax?: string
    totalIncTax: string
    totalTax: string
  }
  onToggleDrawer: (open: boolean) => void
  onAdjustItemQuantity: (input: { id: string; newQty: number }) => void
  onRemoveItem: (input: { id: string }) => void
  onSaveChanges: () => void
}

const OrderDrawer: React.FC<Props> = ({
  open,
  orderData: orderDataProp,
  fees,
  taxRateTotals,
  grandTotals,
  orderItems,
  taxAcronym,
  recalculatingTotals = false,
  recalculatingFees = false,
  canSaveChanges,
  errors,
  saving,
  isPersistentDrawer,
  onToggleDrawer,
  onAdjustItemQuantity,
  onRemoveItem,
  onSaveChanges,
}) => {
  const orderData = useFragment(gmOrderDrawerOrderDataFragment, orderDataProp)
  const charityMeals = useCharityMeals()

  const handleClose = () => {
    onToggleDrawer(false)
  }

  return (
    <Drawer
      variant={isPersistentDrawer ? 'persistent' : 'temporary'}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { sm: 407, xs: '100%' },
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'unset',
        },
      }}
      disableScrollLock
      anchor="right"
      ModalProps={{
        keepMounted: true,
      }}
      open={open}
      onClose={() => handleClose()}
    >
      <Stack
        bgcolor="secondary.main"
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
        padding={1}
        paddingRight={2}
      >
        <IconButton sx={{ width: 'fit-content' }} onClick={() => handleClose()}>
          <Close color="primary" />
        </IconButton>
        <Typography
          color="secondary.contrastText"
          width="100%"
          textAlign="center"
          variant="subtitle2"
        >
          {orderData.orderId}
        </Typography>
      </Stack>
      <Stack padding={1} spacing={1} direction="row" alignItems="center">
        <CalendarToday fontSize="medium" color="primary" />
        <Typography textAlign="left">
          {format(new Date(orderData.deliveryDate), 'do MMM yyyy')}
          &nbsp;
        </Typography>
        <Typography>{orderData.deliveryTimeSlot}</Typography>
      </Stack>
      {errors.INVALID_PAYMENT_METHOD ? (
        <Stack padding={1}>
          <Alert variant="filled" severity="warning">
            Orders paid for by card cannot currently be edited
          </Alert>
        </Stack>
      ) : null}
      {errors.PAST_EDIT_CUT_OFF ? (
        <Stack padding={1}>
          <Alert variant="filled" severity="warning">
            Changes can no longer be made to this order. Please contact support for further
            assistance.
          </Alert>
        </Stack>
      ) : null}
      {errors.SAVE_CHANGES_ERROR ? (
        <Stack padding={1}>
          <Alert variant="filled" severity="error">
            Unable to save changes to your order: {errors.SAVE_CHANGES_ERROR.message}
          </Alert>
        </Stack>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          position: 'relative',
          overflow: 'scroll',
          bgcolor: 'background.default',
        }}
      >
        <Stack padding={1} spacing={1} direction="column">
          {orderItems.map((item) => (
            <ItemCard
              taxAcronym={taxAcronym}
              onAdjustItemQuantity={onAdjustItemQuantity}
              onRemoveItem={onRemoveItem}
              currency={orderData.vendor.currency ?? ''}
              item={item}
              key={item.id}
            />
          ))}
        </Stack>
        {errors.MINIMUM_SPEND_NOT_MET ? (
          <Stack padding={1}>
            <Alert variant="filled" severity="warning">
              You need to spend {getCurrencySymbol(orderData.vendor.currency as Currency)}
              {errors.MINIMUM_SPEND_NOT_MET.amountRequired} ex. {taxAcronym} more to checkout
            </Alert>
          </Stack>
        ) : null}
        <Stack justifyContent="flex-end" height="100%" spacing={2} padding={1} direction="column">
          {grandTotals.discountExTax && (
            <ChargeRow
              isNegativeValue
              description={
                orderData.hasEnterpriseDiscount ? 'Enterprise discount' : `Coupon discount`
              }
              price={grandTotals.discountExTax}
              loading={recalculatingFees}
              currency={orderData.vendor.currency || ''}
            />
          )}
          {fees.map((fee) => (
            <ChargeRow
              key={fee.description}
              description={`${fee.description} (ex ${taxAcronym})`}
              price={fee.totalExTax}
              loading={recalculatingFees}
              currency={orderData.vendor.currency || ''}
            />
          ))}
          <Divider />
          {taxRateTotals.map((taxRateTotal) => (
            <ChargeRow
              key={taxRateTotal.rate}
              description={`${taxAcronym} @ ${taxRateTotal.rate}% item subtotal`}
              price={taxRateTotal.value}
              loading={recalculatingTotals}
              currency={orderData.vendor.currency || ''}
            />
          ))}
          <ChargeRow
            description={`Total ${taxAcronym}`}
            price={grandTotals.totalTax}
            loading={recalculatingTotals}
            currency={orderData.vendor.currency || ''}
          />
        </Stack>
      </Box>
      <Divider />
      <Stack
        direction="row"
        paddingX={2}
        paddingY={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1" display="flex">
          Total to pay&nbsp;
          <Typography variant="body2" color="text.secondary">
            (inc. {taxAcronym})
          </Typography>
        </Typography>
        <Typography variant="body1">
          {recalculatingTotals ? (
            <Skeleton width={50} />
          ) : (
            <>
              {getCurrencySymbol(orderData.vendor.currency as Currency)}
              {grandTotals.totalIncTax}
            </>
          )}
        </Typography>
      </Stack>
      <Divider />
      {charityMeals.enabled ? (
        <>
          <Stack spacing={1} direction="row" paddingX={2} paddingY={1} alignItems="center">
            <Favorite fontSize="small" />
            <Typography>
              You’re feeding {orderData.headCount}, we’ll feed {orderData.headCount} too
            </Typography>
            <Tooltip title="For every meal you order with Feedr, we donate a nutritious meal to a school child in rural India, in partnership with the Akshaya Patra Foundation.">
              <Info fontSize="small" sx={{ width: 17, height: 17 }} />
            </Tooltip>
          </Stack>
          <Divider />
        </>
      ) : null}
      <Stack
        direction="row"
        paddingX={2}
        paddingY={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          onClick={onSaveChanges}
          disabled={!canSaveChanges || saving}
          variant="contained"
          fullWidth
        >
          {saving ? <CircularProgress size={24} /> : 'Save changes'}
        </Button>
      </Stack>
    </Drawer>
  )
}

export default OrderDrawer
