import { MenuItem } from '../../../domain/menu-item'
import { MenuItemOption, MenuOptionItem } from '../../../domain/menu-item-option'
import {
  BasketFragment,
  BasketItemFragment,
  BasketItemInput,
  useAddItemToBasketMutation,
} from '@/generated/graphql'

export type AddItemPayload = Pick<BasketItemInput, 'note' | 'qty'> & {
  item: MenuItem
  options?: {
    option: MenuItemOption
    optionItem: MenuOptionItem
    qty: number
  }[]
}

type AddItemToBasket = (basket: BasketFragment, payload: AddItemPayload) => void

const optimisticMergeBasketItems = (
  items: BasketItemFragment[],
  newItem: BasketItemFragment,
): BasketItemFragment[] => {
  if (items.some((item) => item.itemReference === newItem.itemReference)) {
    return items.map((item) => {
      if (item.itemReference === newItem.itemReference) {
        return {
          ...item,
          qty: item.qty + newItem.qty,
        }
      }
      return item
    })
  }

  return [...items, newItem]
}

export const useAddItemMutation = (setDeliveryFee?: number, isStandingOrderTemplate?: boolean) => {
  const [run, result] = useAddItemToBasketMutation()

  if (!setDeliveryFee && setDeliveryFee !== 0) throw new Error('No delivery fee set')

  const error =
    result.error ||
    (result.called && !result.loading && !result.data?.basket ? 'Unknown error' : null)

  const addItemToBasket: AddItemToBasket = (basket, payload) => {
    const optimisticBasketMenuItem: BasketItemFragment['item'] = {
      ...payload.item,
      variations: [
        {
          __typename: 'MenuItemVariation',
          _id: `core:${payload.item._id}-optimistic-variation-id`,
          varType: 'gm',
          servings: payload.item.servings,
          minQty: payload.item.minQty,
          price: payload.item.price,
          priceExTax: payload.item.priceExTax,
          priceCustomerFacingExTax: payload.item.priceCustomerFacingExTax,
          priceCustomerFacing: payload.item.price,
          taxRate: payload.item.taxRate,
          taxValue: payload.item.taxValue,
          isActive: true,
          taxValueCustomerFacing: payload.item.taxValueCustomerFacing,
        },
      ],
    }

    const optionIds =
      payload.options && payload.options.length > 0
        ? `-O(${payload.options.map((option) => `${option.optionItem.item._id}${option.qty || ''}`).join('-')})`
        : ''

    const trimNotes = payload.note ? `-N(${payload.note.trim()})` : ''
    const optimisticItems = optimisticMergeBasketItems(basket.items, {
      __typename: 'BasketItem',
      qty: payload.qty,
      itemReference: `I(${payload.item._id})${optionIds}${trimNotes}`,
      acknowledgedAt: new Date(),
      note: payload.note || null,
      item: optimisticBasketMenuItem,
      options: (payload.options || []).map(({ qty, option, optionItem }) => ({
        qty,
        item: optionItem.item,
        category: option,
        optionItem,
      })),
    })

    void run({
      variables: {
        basketId: basket.id,
        isStandingOrderTemplate: Boolean(isStandingOrderTemplate),
        itemPayload: {
          acknowledgedAt: new Date(),
          item: payload.item._id,
          note: payload.note,
          options: (payload.options || []).map(({ option, optionItem, qty }) => ({
            categoryId: option._id,
            item: optionItem.item._id,
            optionItemId: optionItem._id,
            qty,
          })),
          qty: payload.qty,
        },
      },
      optimisticResponse: {
        basket: {
          ...basket,
          items: optimisticItems,
        },
      },
    })
  }

  return {
    run: addItemToBasket,
    loading: result.loading,
    error,
  }
}
