import React from 'react'
import { Avatar, Stack, StackProps, Tooltip, Typography } from '@mui/material'
import { Allergen, Dietary, SubAllergens } from '@/generated/gql/graphql'
import AllergensComboInfo from '../AllergensComboInfo'
import DietaryBadge from '../DietaryIconMui'

type Props = {
  dietaries: Dietary[]
  allergens: Allergen[]
  subAllergens?: SubAllergens
  maxToShow?: number
  showLabel?: boolean
  isComboItem?: boolean
} & Pick<StackProps, 'flexWrap'>

const DietaryRow: React.FC<Props> = ({
  dietaries,
  allergens,
  subAllergens,
  maxToShow,
  showLabel,
  isComboItem = false,
  flexWrap = 'wrap',
}) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => {
    if (show) {
      setShow(false)
    } else {
      setShow(true)
    }
  }
  const allItems = [...allergens, ...dietaries]
  const numberOfIconsToShow = isComboItem && maxToShow ? maxToShow - 1 : maxToShow
  const itemsToShow = numberOfIconsToShow ? allItems.slice(0, numberOfIconsToShow) : allItems
  const itemsInPopover = numberOfIconsToShow ? allItems.slice(numberOfIconsToShow) : []
  return (
    <Stack useFlexGap flexWrap="wrap" spacing={showLabel ? 2 : 0.5} direction="row">
      {isComboItem ? <AllergensComboInfo infoAsTooltip={!showLabel} /> : null}
      {itemsToShow.map((item) => (
        <DietaryBadge displayLabel={showLabel} key={item} type={item} subAllergens={subAllergens} />
      ))}
      {itemsInPopover.length > 0 ? (
        <div
          onFocus={() => {}}
          style={{ display: 'inline' }}
          onMouseOver={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <Tooltip
            open={show}
            onClick={handleClick}
            title={
              <Stack direction="row" flexWrap={flexWrap} spacing={showLabel ? 2 : 0.5}>
                {itemsInPopover.map((item) => (
                  <DietaryBadge
                    displayLabel={showLabel}
                    key={item}
                    type={item}
                    subAllergens={subAllergens}
                  />
                ))}
              </Stack>
            }
          >
            <Avatar sx={{ width: 26, height: 26, cursor: 'pointer' }}>
              <Typography variant="caption">+ {itemsInPopover.length}</Typography>
            </Avatar>
          </Tooltip>
        </div>
      ) : null}
    </Stack>
  )
}

export default DietaryRow
